import React, { FormEvent, useEffect, useState } from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../../components/layout";
import { PageHeaderSection } from "../../components/rebrand/sections/PageHeader/PageHeaderSection";
import { SEO } from "../../components/seo";

export const pageQuery = graphql`
  query ContactSuccessPage {
    site {
      siteMetadata {
        pages {
          contact {
            title
            description
          }
        }
      }
    }
  }
`;

interface ContactSuccessPageProps extends PageProps {
  data: Queries.ContactSuccessPageQuery;
}

export default function DemoSuccessPage(props: ContactSuccessPageProps) {
  const { data } = props;
  const { title, description } = data.site?.siteMetadata?.pages?.contact as Queries.SiteSiteMetadataPageSuccessContact;

  const seo = {
    title: title as string,
    description: description as string,
  };

  return (
    <Layout>
      <SEO {...seo} />
      <PageHeaderSection
        title="¡Gracias por tu mensaje!"
        subtitle={
          <>
            Pronto nos podremos en contacto contigo.
          </>
        }
      />
    </Layout>
  );
}
